.login-html,
.login-body {
  height: 100%;
  width: 100%;
}

.login-body .wrapper.wrapper-full-page {
   height: 100%;
}

.login-body .full-page {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.login-body .full-page-background {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}


.login-body .full-page > .content {
    position: relative;
    z-index: 4;
   max-width: 370px;
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;

}

.login-body .footer {
  padding-bottom: 10px;
}