.m-none {
  margin: 0 !important;
}
// set margin
$margins :(
  'm-0'  : 0px,
  'm-5'  : 5px,
  'm-10' : 10px,
  'm-20' : 20px,
  'm-30' : 30px,
  'm-40' : 40px,
  'm-50' : 50px,
  'm-60' : 60px,
  'm-70' : 70px,
  'm-80' : 80px,
  'm-90' : 90px,
  'm-100': 100px
);

@each $margin, $val in $margins {

  .#{$margin}-t {
    margin-top: $val;
  }

  .#{$margin}-b {
    margin-bottom: $val;
  }
}
