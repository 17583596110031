.card{
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 25px 0;

    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
    border-radius: $border-radius-base;
    color: $mdb-card-body-text;
    background: $mdb-card-body-background;

    .card-height-indicator {
        margin-top: 100%;
    }

    .title{
        margin-top: 0;
        margin-bottom: 5px;
    }
    .card-image {
        height: 60%;
        position: relative;
        overflow: hidden;

        margin-left: 15px;
        margin-right: 15px;
        margin-top: -30px;
        border-radius: $border-radius-large;

        img {
            width: 100%;
            height: 100%;
            border-radius: $border-radius-large;
            pointer-events: none;
        }
        .card-title {
            position: absolute;
            bottom: 15px;
            left: 15px;
            color: $mdb-card-image-headline;
            font-size: $font-size-h4;
            text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
        }
    }

    .category:not([class*="text-"]){
        color: $gray-color;
    }
    .card-content{
        padding: 5px 20px 15px 20px;
        position: relative;
        .category{
            margin-bottom: 0;
        }
    }

    .card-header{
        @include shadow-big();
        margin: -20px $margin-base 0;
        border-radius: $border-radius-base;
        padding: $padding-base;
        background-color: $gray-color;

        .title{
            color: $white-color;
        }
        .category{
            margin-bottom: 0;
            color: rgba($white-color, .62);
        }

        &.card-chart{
            padding: 0;
            min-height: 160px;

            + .content{
                h4{
                    margin-top: 0;
                }
            }
        }

        .ct-label{
            color: rgba($white-color, .7);
        }
        .ct-grid{
            stroke: rgba(255, 255, 255, 0.2);
        }
        .ct-series-a .ct-point,
        .ct-series-a .ct-line,
        .ct-series-a .ct-bar,
        .ct-series-a .ct-slice-donut{
            stroke: rgba(255,255,255,.8);
        }
        .ct-series-a .ct-slice-pie,
        .ct-series-a .ct-area{
            fill: rgba(255,255,255,.4);
        }

    }

    .chart-title{
        position: absolute;;
        top: 25px;
        width: 100%;
        text-align: center;

        h3{
            margin: 0;
            color: $white-color;
        }

        h6{
            margin: 0;
            color: rgba(255,255,255, .4);
        }

    }

    .card-footer{
        margin: 0 20px 10px;
        padding-top: 10px;
        border-top: 1px solid #eeeeee;
        position: relative;
        .content{
            display: block;
        }

        div{
          //  display: inline-block;
        }
        .author{
            color: $gray-color;
        }
        .stats{
            line-height: 22px;
            color: $gray-color;
            font-size: $font-size-small;

            .material-icons{
                position: relative;
                top: 4px;
                font-size: $font-paragraph;
            }
        }

        h6{
            color: $gray-color;
        }

    }

    img{
        width: 100%;
        height: auto;
    }

    .category{
        .material-icons{
            position: relative;
            top: 6px;
            line-height: 0;
        }
    }

    .category-social{
        .fa{
            font-size: 24px;
            position: relative;
            margin-top: -4px;
            top: 2px;
            margin-right: 5px;
        }
    }

    .author{
        .avatar{
            width: 30px;
            height: 30px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 5px;
        }

        a{
            color: $black-color;
            text-decoration: none;

            .ripple-container{
                display: none;
            }
        }
    }

    .table{
        margin-bottom: 0;

        tr:first-child td{
            border-top: none;
        }
    }

    [data-background-color="purple"]{
        background: linear-gradient(60deg, $purple-400, $purple-600);
        @include shadow-big-color($brand-primary);
    }

    [data-background-color="blue"]{
        background: linear-gradient(60deg, $cyan-400, $cyan-600);
        @include shadow-big-color($brand-info);
    }

    [data-background-color="green"]{
        background: linear-gradient(60deg, $green-400, $green-600);
        @include shadow-big-color($brand-success);
    }

    [data-background-color="orange"]{
        background: linear-gradient(60deg, $orange-400, $orange-600);
        @include shadow-big-color($brand-warning);
    }

    [data-background-color="red"]{
        background: linear-gradient(60deg, $red-400, $red-600);
        @include shadow-big-color($brand-danger);
    }

    [data-background-color]{
        color: $white-color;

        a{
            color: $white-color;
        }
    }
}

.card-stats{
    .title{
        margin: 0;
    }
    .card-header{
        float: left;
        text-align: center;

        i{
            font-size: 36px;
            line-height: 56px;
            width: 56px;
            height: 56px;
        }
    }
    .card-content{
        text-align: right;
        padding-top: 10px;
    }

}

.card-nav-tabs{
    .header-raised{
        margin-top: -$margin-base * 2;
    }
    .nav-tabs{
        background: transparent;
        padding: 0;
    }
    .nav-tabs-title{
        float: left;
        padding: 10px 10px 10px 0;
        line-height: 24px;
    }
}

.card-plain{
    background: transparent;
    box-shadow: none;

    .card-header{
        margin-left: 0;
        margin-right: 0;
    }
    .content{
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-image{
        margin: 0;
        border-radius: $border-radius-base;

        img{
            border-radius: $border-radius-base;
        }
    }
}

.iframe-container{
    margin: 0 -20px 0;

    iframe{
        width: 100%;
        height: 500px;
        border: 0;
        @include shadow-big();
    }
}

.card-profile,
.card-testimonial{
    margin-top: 30px;
    text-align: center;

    .btn-just-icon.btn-raised{
        margin-left: 6px;
        margin-right: 6px;
    }

    .card-avatar{
        max-width: 130px;
        max-height: 130px;
        margin: -50px auto 0;
        border-radius: 50%;
        overflow: hidden;

        @include shadow-big();

        & + .content{
            margin-top: 15px;
        }
    }

    &.card-plain{
        .card-avatar{
            margin-top: 0;
        }
    }
}


.card .card-actions {
    
    text-align: center;

    button {
        margin: 0;
    }
}

.management {
   .card-content {
      padding-bottom: 5px;
   }

   .card-title {
     margin-top: 10px;
     margin-bottom: 10px;
     font-weight: 400;
     text-align: center;
   }
}