/*!
 * Jasny Bootstrap v3.1.0 (http://jasny.github.com/bootstrap)
 * Copyright 2011-2014 Arnold Daniels.
 * Licensed under Apache-2.0 (https://github.com/jasny/bootstrap/blob/master/LICENSE)
 */

.nav-tabs-bottom {
  border-bottom: 0;
  border-top: 1px solid #dddddd;
}
.nav-tabs-bottom > li {
  margin-bottom: 0;
  margin-top: -1px;
}
.nav-tabs-bottom > li > a {
  border-radius: 0 0 4px 4px;
}
.nav-tabs-bottom > li > a:hover,
.nav-tabs-bottom > li > a:focus,
.nav-tabs-bottom > li.active > a,
.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-bottom > li.active > a:focus {
  border: 1px solid #dddddd;
  border-top-color: transparent;
}
.nav-tabs-left {
  border-bottom: 0;
  border-right: 1px solid #dddddd;
}
.nav-tabs-left > li {
  margin-bottom: 0;
  margin-right: -1px;
  float: none;
}
.nav-tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  margin-bottom: 2px;
}
.nav-tabs-left > li > a:hover,
.nav-tabs-left > li > a:focus,
.nav-tabs-left > li.active > a,
.nav-tabs-left > li.active > a:hover,
.nav-tabs-left > li.active > a:focus {
  border: 1px solid #dddddd;
  border-right-color: transparent;
}
.row > .nav-tabs-left {
  padding-right: 0;
  padding-left: 15px;
  margin-right: -1px;
  position: relative;
  z-index: 1;
}
.row > .nav-tabs-left + .tab-content {
  border-left: 1px solid #dddddd;
}
.nav-tabs-right {
  border-bottom: 0;
  border-left: 1px solid #dddddd;
}
.nav-tabs-right > li {
  margin-bottom: 0;
  margin-left: -1px;
  float: none;
}
.nav-tabs-right > li > a {
  border-radius: 0 4px 4px 0;
  margin-left: 0;
  margin-bottom: 2px;
}
.nav-tabs-right > li > a:hover,
.nav-tabs-right > li > a:focus,
.nav-tabs-right > li.active > a,
.nav-tabs-right > li.active > a:hover,
.nav-tabs-right > li.active > a:focus {
  border: 1px solid #dddddd;
  border-left-color: transparent;
}
.row > .nav-tabs-right {
  padding-left: 0;
  padding-right: 15px;
}
.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
}
.fileinput {
  margin-bottom: 9px;
  display: inline-block;
}
.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
}
.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
  border: none;
  padding: 0;
  max-width: 300px;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 
              0 4px 25px 0px rgba(0, 0, 0, 0.12),
              0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.fileinput .thumbnail.full {
  max-width: 100%;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}
.fileinput .btn {
  vertical-align: middle;
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
  display: table;
}
.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}
.fileinput.input-group > .btn-file {
  z-index: 1;
}
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}
.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}
.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}
.form-group.has-warning .fileinput .fileinput-preview {
  color: #8a6d3b;
}
.form-group.has-warning .fileinput .thumbnail {
  border-color: #faebcc;
}
.form-group.has-error .fileinput .fileinput-preview {
  color: #a94442;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: #ebccd1;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: #3c763d;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: #d6e9c6;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
